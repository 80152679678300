import 'adminlte/dist/js/adminlte'

$(document).ready(() => {
    setTimeout(() => {
        $(document).Toasts('create', {
            class: 'bg-info',
            title: 'Ново!',
            subtitle: '',
            body: 'Вече можете да се абонирате за нашия <a href="">бюлетин</a>'
        })
    }, 1500)
})
